<template>
    <div v-loading="loading">
        <section>
            <article class="mt-5">
            <div class="container mt-5">
                <div class="row w-100 mb-4">
                <div class="col-12 pl-3 px-0 mb-0 text-left">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/' }">Home</el-breadcrumb-item>
                    <el-breadcrumb-item>Review</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                </div>
                <h1 style="font-size: 1.5em; font-weight: 600;">Review:</h1>
                <div
                style="display: flex; align-items:center; justify-content: space-between;"
                class="search_add_section"
                >
                <div style="width: 50%;">
                    <input
                    type="text"
                    v-model.trim="filters[0].value"
                    placeholder="Search by Name"
                    class="search_by_input form-control"
                    spellcheck="off"
                    />
                </div>
                <div> </div>
                </div>
                <hr />
    
                <div
                v-loading="loading || is_changing"
                class="table-responsive mb-5"
                >
                <data-tables
                    :data="reviews"
                    :table-props="tableProps"
                    :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                    type="expand"
                    :filters="filters"
                >
    
                    <div slot="empty">
                    <ScaleOut
                        v-if="isLoading && !loadingError"
                        class="mt-3"
                        :background="'#164B70'"
                    />
                    <div
                        @click="getReview"
                        v-else-if="!isLoading && loadingError"
                        style="cursor: pointer"
                    >
                        Unable to Load reviews Now. Please click here to retry
                    </div>
                    <div v-if="!isLoading && !loadingError">No reviews</div>
                    </div>
    
                    <el-table-column
                    label="#"
                    :show-overflow-tooltip="false"
                    label-class-name="text-center"
                    class-name="text-center"
                    width="80px"
                    >
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                    </el-table-column>
    
                    <el-table-column
                    label="Review Info"
                    :show-overflow-tooltip="false"
                    label-class-name="text-left"
                    class-name="text-left"
                    >
                    <template slot-scope="scope">
                      <el-rate
                        v-model="scope.row.rating"
                        :colors="colors">
                      </el-rate>
                      {{ scope.row.reviewMessage }}
                    </template>
                    </el-table-column>
    
                    <el-table-column
                    label="Room Name"
                    :show-overflow-tooltip="false"
                    label-class-name="text-center"
                    class-name="text-center"
                    >
                    <template slot-scope="scope">
                        {{ scope.row.roomNumber }}
                    </template>
                    </el-table-column>
    
                    <el-table-column
                    label="Actions"
                    :show-overflow-tooltip="false"
                    label-class-name="text-center"
                    class-name="text-center"
                    >
                    <template slot-scope="scope">
                        <el-tooltip
                            content="Delete"
                            placement="top"
                        >
                            <el-button
                            type="danger"
                            :disabled="false"
                            @click="deleteReview(scope.row.feedbackID)"
                            icon="el-icon-delete"
                            circle
                            ></el-button>
                        </el-tooltip>
                    </template>
                    </el-table-column>
    
                </data-tables>
                </div>
    
            </div>
            </article>
        </section>

        
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        reviews: [],
        
        tableProps: {
          border: true,
          stripe: true,
        },
        filters: [
          {
            prop: "name",
            value: "",
          },
        ],
        loadingError: false,
        
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'], // same as { 2: '#99A9BF', 4: { value: '#F7BA2A', excluded: true }, 5: '#FF9900' }
      
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getReview();
    },
  
    methods: {
  
      async getReview() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`reviews`);
          if (request.data.success && request.data.message === "REVIEWS_FETCHED_SUCCESSFULLY") {
            this.reviews = request.data.reviews;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch reviews now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteReview(feedbackID) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Review. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`reviews/${feedbackID}`);
              if (
                request.data.success &&
                request.data.message === "REVIEW_DELETED_SUCCESSFULLY"
              ) {
                this.getReview();
                this.$notify({
                  title: "Success",
                  message: "Review deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the Review, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>